import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import * as Keycloak from 'keycloak-js';
import { ENVIRONMENT } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { KeycloakConfig, KeycloakInstance } from 'keycloak-js';
import { Observable, throwError } from 'rxjs';

import { AppSession } from '../../../session/app-session';
import { AuthService } from './auth.service';
import { CONSTANTS } from '../../constants';
import { IMenuInterface } from '../../../models/menu-interface';
import { KcToken } from '../models/kc-token';
import { KcUser } from '../models/kc-user';
import { KcUserInfo } from '../models/kc-user-info';
import { MobileConfig } from '../../constants/mobile-config';

const KEYCLOAK_ERROR = 'Authentication keycloak error: ';
const TOKEN_VALIDITY = 300;

@Injectable({ providedIn: 'root' })
export class KeycloakAuthenticationService {
  private keycloakConfig: KeycloakConfig;
  private keycloakAuthService: KeycloakInstance;

  constructor(private http: HttpClient,
    private authService: AuthService) {
    this.keycloakConfig = {
      url: ENVIRONMENT.KEYCLOAK_SERVER_AUTH_URL,
      realm: ENVIRONMENT.KEYCLOAK_REALM,
      clientId: ENVIRONMENT.KEYCLOAK_CLIENT_ID,
    };
  }

  /**
   * @description Init keycloak service and hook.
   * @return Returns whether Keycloak is initialized or not.
   */
  public async init(): Promise<boolean> {
    this.keycloakAuthService = Keycloak(this.keycloakConfig);
    return await this.keycloakAuthService.init({ onLoad: 'login-required', checkLoginIframe: false }).then((keycloakConfig) => {
      this.authService.sendLoggedInStatus(true);
      return keycloakConfig;
    }).catch((error) => {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    });
  }

  /**
   * @description Retrieve the keycloak service instance.
   * @returns Keycloak instance
   */
  public getKeycloakAuthService(): KeycloakInstance {
    try {
      return this.keycloakAuthService;
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Retrieve the keycloak service token.
   * @returns Encoded token.
   */
  public async getToken(): Promise<string> {
    return await this.keycloakAuthService?.updateToken(TOKEN_VALIDITY).then(() => {
      return this.keycloakAuthService.token;
    }).catch((error) => {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    });
  }

  /**
   * @description Retrieve the parsed keycloak service token.
   * @returns Parsed token.
   */
  public getTokenParsed(): KcUser {
    try {
      let kcToken: KcToken = new KcToken();
      let kcUser: KcUser = new KcUser();

      kcToken = this.keycloakAuthService.tokenParsed;

      if (!_.isEmpty(kcToken.family_name)) {
        let lastNames = kcToken.family_name.split(' ');
        kcUser.lastName = lastNames[0];
        kcUser.secondLastName = lastNames[1];
      }
      kcUser.userId = kcToken.sub;
      kcUser.username = kcToken.preferred_username;
      kcUser.firstName = kcToken.given_name;
      kcUser.fullName = kcToken.name;
      kcUser.email = kcToken.email;
      kcUser.emailVerified = kcToken.email_verified;
      kcUser.roles = kcToken.realmRoles;

      return kcUser;
    } catch (error) {
      throwError(error || 'Server error');
    }
  }

  /**
   * @description This method logs the user out of the keycloak server.
   * @return {void}
   */
  public async kcUserLogOut(): Promise<void> {
    try {
      this.authService.removeAllowedRoutes();
      this.removeAppSession();
      await this.keycloakAuthService.logout();
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description This method logs the user out of the system.
   * @return {void}
   */
  public userLogOut(): void {
    let user: KcUser = this.getTokenParsed();
    let logout: Observable<Object>;
    let mobile: boolean = window.screen.width < MobileConfig.WINDOW_WIDTH;
    if (mobile) {
      logout = this.http.post(ENVIRONMENT.API + '/auth/logout-rf/' + user.userId, null);
    } else {
      logout = this.http.post(ENVIRONMENT.API + '/auth/logout/' + user.userId, null);
    }

    logout.subscribe(async () => {
      this.authService.removeAllowedRoutes();
      this.removeAppSession();
      localStorage.clear();
      this.authService.sendLoggedInStatus(false);
      await this.keycloakAuthService.logout();
    }, async (error) => {
      this.authService.removeAllowedRoutes();
      this.removeAppSession();
      localStorage.clear();
      this.authService.sendLoggedInStatus(false);
      await this.keycloakAuthService.logout();
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    });
  }

  /**
   * @description Returns whether the user is authenticated or not.
   * @returns {boolean}
   */
  public isAuthenticated(): boolean {
    try {
      let warehouseId: number;
      const userAuthenticated: boolean = this.keycloakAuthService.authenticated;
      const allowedRoutes: IMenuInterface[] = this.authService.getAllowedRoutes();
      warehouseId = this.retrieveAppSession().warehouseId;
      if (userAuthenticated && !_.isNull(allowedRoutes) && !_.isEqual(warehouseId, CONSTANTS.ZERO)
        && !_.isUndefined(warehouseId)) {
        return true;
      }
      return false;
    } catch (exception) {
      this.authService.removeAllowedRoutes();
      this.removeAppSession();
      return false;
    }
  }

  /**
   * @description Returns whether the user is authenticated or not in Keycloak Server.
   * @returns {boolean}
   */
  public isKeycloakAuthenticated(): boolean {
    try {
      return this.keycloakAuthService.authenticated;
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns an array of realm roles associated with the user.
   * @returns Realm roles.
   */
  public getUserAssociatedRealmRoles(): Array<string> {
    try {
      return this.keycloakAuthService.realmAccess.roles;
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns an array of resource roles associated with the user.
   * @returns Resource roles.
   */
  public getUserAssociatedResourceRoles(): any {
    try {
      return this.keycloakAuthService.resourceAccess;
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns whether the user has the real role associated or not.
   * @param role - Realm role that is compared within the token.
   * @returns Associated realm role.
   */
  public userHasRealmRole(role: string): boolean {
    try {
      return this.keycloakAuthService.hasRealmRole(role);
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns whether the user has the resource role associated or not.
   * @param role - Resource role that is compared within the token.
   * @returns Associated resource role.
   */
  public userHasResourceRole(role: string): boolean {
    try {
      return this.keycloakAuthService.hasResourceRole(role);
    } catch (error) {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    }
  }

  /**
   * @description Returns the user information within the token.
   * @returns User information.
   */
  public getKcUserInfo(): Promise<KcUser> {
    let kcUser = new KcUser();
    return this.keycloakAuthService.loadUserInfo().then((userInfo: KcUserInfo) => {
      let lastNames = userInfo.family_name.split(' ');
      kcUser.userId = userInfo.sub;
      kcUser.username = userInfo.preferred_username;
      kcUser.firstName = userInfo.given_name;
      kcUser.lastName = lastNames[0];
      kcUser.secondLastName = lastNames[1];
      kcUser.fullName = userInfo.name;
      kcUser.email = userInfo.email;
      kcUser.emailVerified = userInfo.email_verified;
      kcUser.roles = userInfo.realmRoles;
      return kcUser;
    }).catch(error => {
      throw new Error(`${KEYCLOAK_ERROR} ${error}`);
    });
  }

  /**
   * @description Stores the user app session to localstorage.
   * @return {void}
   */
  public storeAppSession(appSession: AppSession): void {
    localStorage.setItem(CONSTANTS.USER_SESSION, JSON.stringify(appSession));
  }

  /**
   * @description Retrieves the user app session from localstorage
   * @return {AppSession}
   */
  public retrieveAppSession(): AppSession {
    return JSON.parse(localStorage.getItem(CONSTANTS.USER_SESSION));
  }

  /**
   * @description Removes the user app session from local storage
   * @return {void}
   */
  public removeAppSession(): void {
    localStorage.removeItem(CONSTANTS.USER_SESSION);
  }
}
