import { CommonModule } from '@angular/common';
import { CustomLoadingComponent } from './custom-loading.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [CommonModule, NgxSpinnerModule],
  declarations: [CustomLoadingComponent],
  exports: [CustomLoadingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomLoadingModule { }
