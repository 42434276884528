import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Account } from '../account/account';
import { AppSession } from '../../session/app-session';
import { ENVIRONMENT } from '../../../environments/environment';
import { HandleError } from '../../utils/utils';
import { KeycloakAuthenticationService } from '../../shared/authentication/services/keycloak-authentication.service';
import { Warehouse } from './warehouse';
import { WarehouseAccount } from '../warehouse-account/warehouse-account';

@Injectable()
export class WarehouseService {

  constructor(private http: HttpClient, private keycloakAuthService: KeycloakAuthenticationService) { }

  /**
   * @description Gets the warehouse's related Accounts
   * @param {Warehouse} warehause Warehouse The base warehouse
   * @returns {Observable<any>}
   */
  public getRelatedAccounts(warehouse: Warehouse): Observable<Account[]> {
    return this.http.get<Account[]>(ENVIRONMENT.API + '/warehouses/' + warehouse.id + '/accounts')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets all the relations between warehouse and account
   * @returns {Observable<WarehouseAccount>}
   */
  public getWarehouseAccountRelations(): Observable<WarehouseAccount[]> {
    return this.http.get<WarehouseAccount[]>(ENVIRONMENT.API + '/warehouses-accounts')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets the list of warehouses
   * @return {Observable<Warehouse[]>} - List of Warehouses
   */
  public getWarehousesInfo(): Observable<Warehouse[]> {
    return this.http.get<Warehouse[]>(ENVIRONMENT.API + '/warehouses')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets an warehouse by its id
   * @param {number} warehouseId Warehouse Id
   * @return {Observable<Warehouse>} Warehouse information
   */
  public getWarehouseById(warehouseId: number): Observable<Warehouse> {
    return this.http.get<Warehouse>(ENVIRONMENT.API + '/warehouses/' + warehouseId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Creates a new warehouse
   * @param {Warehouse} warehouse Warehouse with the information which will be created.
   * @return {Observable<Warehouse>} - Created warehouse.
   */
  public saveWarehouse(warehouse: Warehouse): Observable<Warehouse> {
    return this.http.post<Warehouse>(ENVIRONMENT.API + '/warehouses/', warehouse)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Updates the Warehouse Information
   * @param {Warehouse} warehouse Warehouse with the information which will be updated.
   * @return {Observable<Warehouse>} - Updated warehouse.
   */
  public updateWarehouse(warehouse: Warehouse): Observable<Warehouse> {
    return this.http.put<Warehouse>(ENVIRONMENT.API + '/warehouses', warehouse)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Deletes the current warehouse
   * @param {Warehouse} warehouse Warehouse's object with the id which will be deleted
   * @return {Observable<Warehouse>} - Deleted warehouse.
   */
  public deleteWarehouse(warehouse: Warehouse): Observable<Warehouse> {
    return this.http.put<Warehouse>(ENVIRONMENT.API + '/warehouses/' + warehouse.id, null)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets the list of warehouses by customer.
   * @return {Observable<Warehouse[]>}
   */
  public getWarehousesWepCustomer(): Observable<Warehouse[]> {
    let session: AppSession = this.keycloakAuthService.retrieveAppSession();
    let criteria: any[] = [];
    criteria.push({ field: 'id', condition: '!=', value: session.warehouseId });
    return this.http.get<Warehouse[]>(ENVIRONMENT.API + '/warehouses?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }
}
