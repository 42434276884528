import { catchError } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import * as _ from 'lodash';

import { KeycloakAuthenticationService } from '../services/keycloak-authentication.service';

const NO_TOKEN_MSG = 'NO_TOKEN_MSG';
const INVALID_TOKEN = 'INVALID_TOKEN';

@Injectable()
export class KeycloakErrorInterceptor implements HttpInterceptor {
  constructor(private keycloakAuthService: KeycloakAuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err: any) => {
      if (_.isEqual(err.error.message, INVALID_TOKEN) || _.isEqual(err.error.message, NO_TOKEN_MSG)) {
        this.keycloakAuthService.userLogOut();
      }
      return throwError(err);
    }));
  }
}
