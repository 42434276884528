import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppSession } from '../../session/app-session';
import { ENVIRONMENT } from '../../../environments/environment';
import { HandleError } from '../../utils/utils';
import { KeycloakAuthenticationService } from '../../shared/authentication/services/keycloak-authentication.service';
import { Observable } from 'rxjs';
import { PendingWork } from './pending-work';

@Injectable()
export class PendingWorkService {
  constructor(private http: HttpClient, private keycloakAuthService: KeycloakAuthenticationService) { }

  /**
   * @description Obtains the pending work.
   * @param {string} type - module from which the task will be recovered.
   * @return {Observable<PendingWork>} the recovered information.
   */
  public findPendingWork(type: string): Observable<PendingWork> {
    return this.http.get<PendingWork>(ENVIRONMENT.API + '/task/pending/work?type=' + type)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Obtains pending work by criteria.
   * @param {any[]} criteriaFilters - criteria filters array.
   * @return {Observable<PendingWork>} the recovered information.
   */
  public findPendingWorkByCriteria(criteriaFilters: any[]): Observable<PendingWork> {
    let criteria = [];
    let session: AppSession = this.keycloakAuthService.retrieveAppSession();
    criteria.push({
      field: 'movement_history_temporal.movement_type',
      condition: 'in',
      value: criteriaFilters
    });
    criteria.push({
      field: 'equipment.id',
      condition: '=',
      value: session.equipmentId
    });

    return this.http.get<PendingWork>(ENVIRONMENT.API + '/task/pending/work?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Obtains the pending work.
   * @return {Observable<PendingWork>} the recovered information.
   */
  public findAnyPendingWork(): Observable<PendingWork> {
    return this.http.get<PendingWork>(ENVIRONMENT.API + '/task/pending/work')
      .pipe(catchError(HandleError.handleErrorObservable));
  }
}
