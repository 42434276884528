import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { Activity } from '../activity/activity';
import { ENVIRONMENT } from '../../../environments/environment';
import { HandleError } from '../../utils/utils';
import { Role } from './role';
import { RoleView } from './role-view';
import { User } from '../user/user';

const LOCALSTORAGE_ROLES = 'roles';
const LOCALSTORAGE_ROLE_VIEWS = 'roleViews';

@Injectable()
export class RoleService {
  private role: Role;
  private view: any[];

  constructor(private http: HttpClient) {
    this.role = new Role();
    this.view = [];
  }

  /**
   * @description This method adds a role  in the frontend if the backend response is true
   * @param {Role[]} roles Roles array.
   * @return {void}
   */
  public setCachedRoles(roles: Role[]): void {
    this.deleteLocalStorage();
    localStorage.setItem('roles', JSON.stringify(roles));

    let rolesViews: Array<RoleView> = [];
    _.forEach(roles, (role: Role) => {
      rolesViews.push({
        role: role.id,
        views: _.map(role.activities, (activity: Activity) => activity.id )
      });
    });
    this.setCachedRoleViews(rolesViews);
  }

  /**
   * @description This method adds a role
   * @param  role {Role} Role's information
   * @param activities : Array<number> views to associate with the role
   */
  public addRole(role: Role, activities: Array<number>): Observable<number> {
    let jsonObjectRole: Object = {
      role: role,
      activities: activities
    };
    return this.http.post<number>(ENVIRONMENT.API + '/roles', jsonObjectRole)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method removes a role from backend
   * @param  role : Role role name to delete
   */
  public deleteRole(role: Role): Observable<Role> {
    return this.http.put<Role>(ENVIRONMENT.API + '/roles/' + role.id, null)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method removes the items saved in the localstorage
   */
  public deleteLocalStorage(): void {
    localStorage.removeItem(LOCALSTORAGE_ROLES);
    localStorage.removeItem(LOCALSTORAGE_ROLE_VIEWS);
  }

  /**
   * @description This method removes a role from frontend, is called after backend deletion is successful
   * @param roles: Role[] view's role properties
   * @param  role : Role role name to delete
   */
  public deleteRoleFrontend(roles: Role[], role: Role): void {
    let indexRole = this.getCachedRoles().indexOf(role);
    let roleView = this.getCachedRoleViews()
      .find((_roleView: RoleView) => _roleView.role === role.id);
    let indexRoleView = this.getCachedRoleViews().indexOf(roleView);
    this.getCachedRoles().splice(indexRole, 1);
    this.getCachedRoleViews().splice(indexRoleView, 1);
    roles.splice(indexRole, 1);
    localStorage.setItem('roleViews', JSON.stringify(this.getCachedRoleViews()));
    localStorage.setItem('roles', JSON.stringify(this.getCachedRoles()));
  }

  /**
   * @description This method edits a role into the backend
   * @param role {Role} Role with the updated properties
   * @return {Observable<Role>} Response from the backend
   */
  public editRole(role: Role, activities: Array<number>): Observable<Role> {
    let jsonObjectRole: Object = {
      role: role,
      activities: activities
    };
    return this.http.put<Role>(ENVIRONMENT.API + '/roles/', jsonObjectRole)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method edits the role with the new properties into the frontend
   * @param  oldRole : Role  old role to edit
   * @param newRole : any new rol with the new properties
   */
  public editRoleFrontend(oldRole: Role, newRole: any): void {
    let roleId = oldRole.id;
    this.role = this.getCachedRoles()
      .filter((role: Role) => role.id === Number(roleId))[0];
    this.view = this.getCachedRoleViews()
      .filter((roleView: RoleView) => roleView.role === Number(roleId))[0].views;
    this.role.name = newRole.roleName;
    this.view = newRole.viewsSelector;
    localStorage.setItem('roleViews', JSON.stringify(this.getCachedRoleViews()));
    localStorage.setItem('roles', JSON.stringify(this.getCachedRoles()));
  }

  /**
   * @description Obtein Role and Views by id
   * @param id : any  role id
   * @return an specific object if exists  and null if not
   */
  public findRoleAndViewsById(id: number): Observable<Role> {
    return this.http.get<Role>(ENVIRONMENT.API + '/roles/' + id)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method returns all the Roles from the backend
   * @return ROLES
   */
  public getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(ENVIRONMENT.API + '/roles')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method returns the data storaged in the local machine 'roles'
   * @return localStorage: Role[] the data parsed to Role[] object from the localstorage
   */
  public getCachedRoles(): Role[] {
    return localStorage.getItem('roles') ?
      JSON.parse(localStorage.getItem('roles')) : [];
  }

  /**
   * @description Method that returns the added views
   * @param  oldRole : Role  old role to edit
   * @param newRole : any new rol with the new properties
   * @return activitiesAdded: Array<number> array containing the activities to add
   */
  public getViewsAdded(oldRole: Role, newRole: any): Array<number> {
    let activitiesAdded: Array<number> = [];
    let oldActivities: RoleView = this.getCachedRoleViews()
      .find((roleView: RoleView) => roleView.role === oldRole.id);
    for (let activity of newRole.viewsSelector) {
      if (!oldActivities.views.includes(activity)) {
        activitiesAdded.push(activity);
      }
    }
    return activitiesAdded;
  }

  /**
   * @description Method that returns the deleted views
   * @param  oldRole : Role  old role to edit
   * @param newRole : any new rol with the new properties
   * @return activitiesDeleted: Array<number> array containing the activities to delete
   */
  public getViewsDeleted(oldRole: Role, newRole: any): Array<number> {
    let activitiesDeleted: Array<number> = [];
    let oldActivities: RoleView = this.getCachedRoleViews()
      .find((roleView: RoleView) => roleView.role === oldRole.id);
    for (let activity of oldActivities.views) {
      if (!newRole.viewsSelector.includes(activity)) {
        activitiesDeleted.push(activity);
      }
    }
    return activitiesDeleted;
  }

  /**
   * @param {User} user : User user to check if has the role
   * @param {Role} roles : Role role to verify
   * @return {boolean} true if has the role or false if not
   */
  public hasRole(user: User, lookupRole: Role): boolean {
    let hasRole = false;
    let userRoles: Role[] = user.roles;
    for (let role of userRoles) {
      if (role.id === lookupRole.id) {
        hasRole = true;
        break;
      } else {
        hasRole = false;
      }
    }
    return hasRole;
  }

  /**
   * @param activities : Array<number> array of views id to search
   * @return role: any  null if none of the existing roles have the views and role object if exists
   */
  public hasRepeatedActivities(activities: Array<number>): Role {
    let role: Role;
    let roleViews: Array<RoleView> = this.getCachedRoleViews();
    let roleId: number;

    _.forEach(roleViews, (roleView: RoleView) => {
      if (_.size(roleView.views) === _.size(activities)) {
        _.forEach(roleView.views, (roleViewId: number) => {
          roleId = _.find(activities, (activityId: number) => {
            return roleViewId === activityId;
          });
        });
      }
    });

    if (roleId) {
      let roles: Role[] = this.getCachedRoles();
      role = _.find(roles, {id: roleId});
    }
    return role;
  }

  /**
   * @description Verifies if the role name is available
   * @param roleName: string roleName to verify
   * @return true if it is available or false if not
   */
  public isNameValid(roleName: string): boolean {
    let fixName = this.textWithoutSpaces(roleName);
    let name = this.getCachedRoles().find(
      role => this.textWithoutSpaces(role.name) === fixName
    );

    if (name) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * @description Converts the text into a new text without spaces and in lowercase mode
   * @return text without spaces and in lowercase
   */
  public textWithoutSpaces(text: string): string {
    return text.replace(/ /g, '').toLowerCase();
  }

  /**
	 * @description This method set all the views from the backend
	 * @return void
	 */
  public setCachedRoleViews(rolesViews: Array<RoleView>): void {
    localStorage.setItem('rolesViews', JSON.stringify(rolesViews));
  }

  /**
	 * @description This method returns all the views from the backend
	 * @return {RoleView[]}
	 */
  public getCachedRoleViews(): RoleView[] {
    let rolesViews: RoleView[];
    rolesViews = JSON.parse(localStorage.getItem('rolesViews'));
    return rolesViews ? rolesViews : [];
  }
}
