export class KcToken {
  public sub?: string;
  public auth_time?: number;
  public email?: string;
  public email_verified?: boolean;
  public exp?: number;
  public family_name?: string;
  public given_name?: string;
  public name?: string;
  public preferred_username?: string;
  public realmRoles?: Array<string>;
  public typ?: string;

  constructor() {
    this.sub = '';
    this.auth_time = undefined;
    this.email = '';
    this.email_verified = null;
    this.exp = undefined;
    this.family_name = '';
    this.given_name = '';
    this.name = '';
    this.preferred_username = '';
    this.realmRoles = [];
    this.typ = '';
  }
}
