import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { isNull as _isNull } from 'lodash';
import { from as fromPromise, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { KeycloakAuthenticationService } from '../services/keycloak-authentication.service';

@Injectable()

export class KeycloakTokenInterceptor implements HttpInterceptor {

  constructor(private keycloakAuthService: KeycloakAuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return fromPromise(this.keycloakAuthService.getToken())
      .pipe(switchMap(authToken => {
        if (!_isNull(authToken)) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${authToken}`
            }
          });
          console.log(request.headers);

          return next.handle(request);
        }
      }));
  }
}
