import { catchError, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { ENVIRONMENT } from '../../../environments/environment';
import { HandleError } from '../../utils/utils';
import { Role } from '../role/role';
import { User } from './user';
import { WepError } from '../../shared/wep-error';

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(private http: HttpClient) { }

  /**
   * @description This method adds an user.
   * @param {user} user user object to add.
   * @return {Observable<User>}
   */
  public addUser(user: User): Observable<User> {

    let jsonObject: Object = {
      name: user.name,
      surname: user.surname,
      secondSurname: user.secondSurname,
      nickname: user.nickname,
      roles: user.roles,
      warehouseAccount: user.warehouseAccount,
      employeeNumber: user.employeeNumber,
      password: '',
      isActive: true,
      profile: user.profile,
      // Keycloak User Data
      idKeycloak: '',
      username: user.nickname,
      firstName: user.name,
      lastName: user.surname + ' ' + user.secondSurname,
      emailVerified: true,
      email: '',
      requiredActions: [],
      credentials: [{
        value: user.password,
        temporary: false
      }],
      enabled: true
    };
    return this.saveKeycloakUser(jsonObject).pipe(mergeMap((idKeycloak: string) => {
      jsonObject['idKeycloak'] = idKeycloak;
      return this.http.post<User>(ENVIRONMENT.API + '/users', jsonObject);
    })).pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method adds an user in keycloak server.
   * @param {Object} jsonObject user object to add.
   * @return {Observable<string>}
   */
  public saveKeycloakUser(jsonObject: Object): Observable<string> {
    return this.http.post<string>(ENVIRONMENT.API + '/kc/users', jsonObject)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method removes an user
   * @param {user} _user user  to delete
   * @return {Observable<User>}
   */
  public deleteUser(_user: User): Observable<User> {
    return this.getUserSessions(_user.idKeycloak).pipe(mergeMap((userSessions: Array<any>) => {
      if (!_.isEmpty(userSessions)) {
        let errWep: WepError = new WepError();
        errWep.error = new Error('ACTIVE_SESSION');
        throw errWep;
      } else {
        return this.http.put<User>(ENVIRONMENT.API + '/users/' + _user.id, _user)
          .pipe(mergeMap(() => {
            return this.deleteKeycloakUser(_user);
          }))
          .pipe(catchError(HandleError.handleErrorObservable));
      }
    })).pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method removes an user from Keyclaok server
   * @param {user} _user user  to delete
   * @return {Observable<any>}
   */
  public deleteKeycloakUser(_user: User): Observable<any> {
    return this.http.delete(ENVIRONMENT.API + '/kc/users/' + _user.idKeycloak)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method obtains all the user sessions from the Keycloak Server
   * @param {String} idKeycloak User id
   * @return {Observable<any[]>}
   */
  public getUserSessions(idKeycloak: String): Observable<any> {
    return this.http.get(ENVIRONMENT.API + '/kc/users/' + idKeycloak + '/sessions')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method edits an user in keycloak server and Wep data base.
   * @param {User} user user object to add.
   * @return {Observable<User>}
   */
  public editUser(user: User): Observable<User> {

    let jsonObject: Object = {
      id: user.id,
      name: user.name,
      surname: user.surname,
      secondSurname: user.secondSurname,
      password: user.password,
      employeeNumber: user.employeeNumber,
      roles: user.roles,
      warehouseAccount: user.warehouseAccount,
      profile: user.profile,
      // Keycloak User Data
      idKeycloak: user.idKeycloak,
      firstName: user.name,
      lastName: user.surname + ' ' + user.secondSurname,
      emailVerified: true,
      email: '',
      requiredActions: [],
    };
    return this.updateKeycloakUser(jsonObject).pipe(mergeMap((idKeycloak: string) => {
      return this.http.put<User>(ENVIRONMENT.API + '/users', jsonObject);
    })).pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method edits an user in keycloak server.
   * @param {User} user user object to add.
   * @return {Observable<string>}
   */
  public updateKeycloakUser(jsonObject: Object): Observable<string> {
    return this.http.put<string>(ENVIRONMENT.API + '/kc/users', jsonObject)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method returns all the Users from the backend
   * @return {Observable<User[]>}
   */
  public getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(ENVIRONMENT.API + '/users')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Searches for an user in data base by its keycloak id.
   * @param {number} idKeycloak Number that contain the id about user.
   * @return {Observable<User>}
   */
  public getUserById(idKeycloak: number): Observable<User> {
    return this.http.get<User>(ENVIRONMENT.API + '/users/' + idKeycloak)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method obtains the user roles that exist in the system
   * @return {role[]} Arrangement that contains the roles that the system has or are discharged
   */
  public getUserRoles(rolesBackend: any[]): Role[] {
    let userRoles: Role[] = [];

    for (let role of rolesBackend) {
      let pushRole: Role = new Role();
      pushRole.name = role.name;
      pushRole.id = role.id;
      pushRole.isDefault = role.isDefault === 1;
      pushRole.description = role.description;
      userRoles.push(pushRole);
    }
    return userRoles;
  }

  /**
   * @description Gets the status for the nickname given
   * @param {string} nickname user's new nickname to check
   * @return {Observable<User>}
   */
  public isNicknameAvailable(nickname: string): Observable<User> {
    return this.http.get<User>(ENVIRONMENT.API + '/user/' + nickname + '/verify')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method change the user status
   * @param {User} user - user to perform the lock or unlock action
   * @return {Observable<User>} containing the data according to the response
   */
  public changeKeycloakUserStatus(user: User): Observable<User> {
    return this.getUserByIdKeycloak(user.idKeycloak).pipe(mergeMap((userFound) => {
      let jsonObject: Object = {
        enabled: !userFound.enabled
      };
      return this.http.put<User>(ENVIRONMENT.API + '/kc/users/' + user.idKeycloak + '/switch-block', jsonObject);
    })).pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Searches for an user by its keycloak id in Keycloak Server
   * @param {string} idKeycloak Chain that contain the id about user.
   * @return {Observable<any>}
   */
  public getUserByIdKeycloak(idKeycloak: string): Observable<any> {
    return this.http.get<any>(ENVIRONMENT.API + '/kc/users/' + idKeycloak)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method updates user's password
   * @param {User} user user to update its password
   * @return {Observable<string>} containing the data according to the response
   */
  public updateKcUserPassword(user: User): Observable<string> {
    let jsonObject: Object = {
      value: user.password,
      temporary: false
    };
    return this.http.put<string>(ENVIRONMENT.API + '/kc/users/' + user.idKeycloak + '/change-password', jsonObject)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get all Actives users in RF
   * @returns {Observable<User[]>} Active users found
   */
  public getActiveUsersRf(): Observable<User[]> {
    return this.http.get<User[]>(ENVIRONMENT.API + '/users/active')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Close wep session of the user selected
   * @param {User} selectedUser - Selected user
   * @returns {Observable<void>}
   */
  public closeWepSessionUser(user: User): Observable<void> {
    return this.http.post<void>(ENVIRONMENT.API + '/auth/close-session', user)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Close session of the user selected
   * @param {User} selectedUser - Selected user
   * @returns {Observable<void>}
   */
  public closeSession(user: User): Observable<void> {
    return this.closeWepSessionUser(user).pipe(mergeMap(() => {
      return this.http.post<void>(ENVIRONMENT.API + '/kc/users/' + user.idKeycloak + '/close-session', null);
    })).pipe(catchError(HandleError.handleErrorObservable));
  }
}
