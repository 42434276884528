import { Component, OnInit, AfterViewInit } from '@angular/core';
import { filter, pairwise } from '../../node_modules/rxjs/operators';
import { MenuItem } from 'primeng/api/menuitem';
import { Router, RoutesRecognized } from '@angular/router';
import { ScfCommunicationService, ScfNotification, ScfToastrService } from 'scf-library';
import * as _ from 'lodash';

import { AppSession } from './session/app-session';
import { AppSessionService } from './session/app-session.service';
import { AuthService } from './shared/authentication/services/auth.service';
import { CONSTANTS } from './shared/constants';
import { IMenuInterface } from './models/menu-interface';
import { KeycloakAuthenticationService } from './shared/authentication/services/keycloak-authentication.service';
import { LabelService } from './administration/label-management/label.service';
import { MobileConfig } from './shared/constants/mobile-config';
import { ReleaseTask } from './shared/interfaces/release-task';
import { ReleaseTaskService } from './shared/services/release-task.service';
import { SidebarWrapperService } from './shared/mobile-sidebar/sidebar-wrapper.service';
import { TaskGenericWrapperService } from './shared/templates/html/task-wrapper/task-wrapper.service';
import { TaskStatusEnum } from './process/task/task-enums';
import { User } from './administration/user/user';
import { UserService } from './administration/user/user.service';
import { WepError } from './shared/wep-error';

@Component({
  selector: 'app-pages-component',
  templateUrl: './pages.component.html',
  providers: [AuthService]
})
export class PagesComponent implements OnInit, AfterViewInit {
  private previousRoute: string;
  public currentRoute: string;
  public dockedSize: string;
  public lbl: any;
  public menu: IMenuInterface[];
  public mobile: boolean;
  public mode: string;
  public msgs: any;
  public opened: boolean;
  public position: string;
  public showBackDrop: boolean;
  public trapFocus: boolean;
  public userId: string;
  public userInfo: any;
  public userSession: AppSession;
  public userSettingsBackEl: any;
  public userSettingsItems: MenuItem[];
  public solution: string;
  public owner: string;
  public display: boolean;
  public isLoggedIn: boolean;
  public warehouseId: number;

  constructor(private appSessionService: AppSessionService,
    private keycloakAuthService: KeycloakAuthenticationService,
    private authService: AuthService,
    private labelService: LabelService,
    private notifier: ScfToastrService,
    private releaseTaskService: ReleaseTaskService,
    private router: Router,
    private scfComService: ScfCommunicationService,
    private sidebarWrapperService: SidebarWrapperService,
    private taskWrapperService: TaskGenericWrapperService,
    private userService: UserService) {
    this.currentRoute = null;
    this.isLoggedIn = this.keycloakAuthService.isKeycloakAuthenticated();
    this.lbl = {
      info: this.labelService.labelText('infoPages'),
      sessionExpired: this.labelService.labelText('sessionExpired'),
      successReleaseTask: this.labelService.labelText('taskWrapperUnSelectTask'),
      successTitle: this.labelService.labelText('infoTitle')
    };
    this.mobile = window.screen.width >= MobileConfig.WINDOW_WIDTH ? false : true;
    this.msgs = {
      activeSessionError: {
        tlt: this.lbl.errorTitle,
        msg: this.labelService.labelText('activeSessionError')
      },
      sessionExpired: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('sessionExpired')
      },
      userInfoError: {
        tlt: this.labelService.labelText('errorTitle'),
        msg: this.labelService.labelText('sidebarUserInfoError')
      },
      releaseTaskError: {
        tlt: this.labelService.labelText('errorTitle'),
        msg: this.labelService.labelText('releaseTaskError')
      }
    };
    this.menu = this.authService.getAllowedRoutes();
    this.buildMenu();
    this.display = false;
    this.opened = false;
    this.owner = '';
    this.previousRoute = null;
    this.showBackDrop = true;
    this.solution = CONSTANTS.WEP;
    this.trapFocus = true;
    this.userSession = new AppSession();
    this.userId = this.keycloakAuthService.getTokenParsed().userId;
    this.userInfo = {
      name: ''
    };
  }

  /**
   * @description App init Component.
   * @return {void}
   */
  public ngOnInit(): void {
    this.isUniqueUserSession();
    this.isAuthenticated();
    this.subscribeLoggedStatus();
    this.subscribeRoutes();
    this.subscribeToScfEvents();
  }

  public ngAfterViewInit(): void {
    // this.verifyWrapperStatusSidebar();
  }

  /**
   * @description Subscribes to the observable that indicated if the user is logged-in or not
   * @return {void}
   */
  public subscribeLoggedStatus(): void {
    this.authService.getLoggedInObservable().subscribe(
      (isLoggedIn: boolean) => {
        if (isLoggedIn === false) {
          this.keycloakAuthService.userLogOut();
        }
        this.setWrapperClass();
      });
  }

  /**
   * @description This method gets all user sessions in keycloak server
   * and validates if it's a unique session, if not, logout the user.
   * @return {void}
   */
  public isUniqueUserSession(): void {
    this.userService.getUserSessions(this.userId).subscribe(
      (userSessions: any) => {
        if (_.size(userSessions) > 1) {
          this.notifier.errorAlert(this.msgs.activeSessionError.msg);
          this.keycloakAuthService.kcUserLogOut();
          throw new Error(this.msgs.activeSessionError.msg);
        }

        if (_.isEqual(_.size(userSessions), 0)) {
          this.notifier.errorAlert(this.msgs.sessionExpired.msg);
          this.keycloakAuthService.userLogOut();
          throw new Error(this.msgs.sessionExpired.msg);
        }
      }, (error: WepError) => {
        this.notifier.errorAlert(this.labelService.getWepError(error.message));
        this.keycloakAuthService.userLogOut();
      });
  }

  /**
   * @description Validates if the user is authenticated and has a warehouse account id in session,
   * if not, redirects to login.
   * @return {void}
   */
  public isAuthenticated(): void {
    if (!this.isLoggedIn) {
      this.keycloakAuthService.userLogOut();
    } else {
      this.appSessionService.getAppSession().subscribe(
        (sessionFound: AppSession) => {
          if (!_.isEmpty(sessionFound) && !_.isEqual(sessionFound.warehouseId, CONSTANTS.ZERO)
          && !_.isNull(this.menu)
          ) {
            this.userSession = sessionFound;
            this.getLoggedUser();
            this.keycloakAuthService.storeAppSession(this.userSession);
          } else {
            this.keycloakAuthService.storeAppSession(this.userSession);
            this.router.navigate([CONSTANTS.LOGIN_URL]);
          }
        }, (error: WepError) => {
          this.notifier.errorAlert(this.labelService.getWepError(error.message));
          this.keycloakAuthService.kcUserLogOut();
        });
    }
  }

  /**
   * @description Set class for page-content-wrapper
   * @return {string} Wrapper class
   */
  public setWrapperClass(): string {
    let wClass = '';
    return wClass;
  }

  /**
   * @description Subscribes to the Router Service and switches current and previous route accordingly
   * @return {void}
   */
  private subscribeRoutes(): void {
    this.router.events
      .pipe(filter(event => event instanceof RoutesRecognized))
      .pipe(pairwise())
      .subscribe((events: any[]) => {
        this.currentRoute = events.pop().url;
        this.previousRoute = events.pop().url;
      });
  }

  /**
   * @description Navigate to a previously route selected.
   * @param {Array<String>} routerLink routerLink property to navigate.
   * @return {void}
   */
  public navigateTo(routerLink: Array<string>): void {
    this.router.navigate(routerLink);
  }

  /**
   * @description Build the sidebar Menu
   * @return {void}
   */
   public buildMenu(): void {
    this.menu = this.authService.getAllowedRoutes();
    if (this.mobile) {
      _.remove(this.menu, (mobileRoutes) => _.isUndefined(mobileRoutes.mobile));
    }
  }

  /**
   * @description Gets user logged information.
   * @return {void}
   */
  public getLoggedUser(): void {
    this.userService.getUserById(this.userSession.userId).subscribe(
      (user: User) => {
        this.userInfo.name = user.name + ' ' + user.surname;
        this.owner = this.userInfo.name;
        this.authService.setTimeZone(user.profile.timeZone);
      }, (error: WepError) => {
        this.notifier.errorAlert(this.labelService.getWepError(error.message));
      });
  }

  /**
   * @description This method redirect to login screen
   * @return {void}
   */
  public logOutUser(): void {
    this.validateReleaseTask();
  }

  /**
   * @description validate release task.
   * @return {void}
   */
  private validateReleaseTask(): void {
    this.releaseTaskService.getReleaseTaskAsObservable().subscribe((taskToRelease: ReleaseTask) => {
      if (!_.isUndefined(taskToRelease)) {
        if (_.isEqual(taskToRelease.component, CONSTANTS.PICKING_LIST_COMPONENT) &&
          _.isEqual(taskToRelease.status, TaskStatusEnum.Assigned)) {
          this.taskWrapperService.releasePickingList(taskToRelease).subscribe(() => {
            this.notifier.infoAlert(this.lbl.successReleaseTask);
            this.keycloakAuthService.userLogOut();
            this.releaseTaskService.releaseObservable();
          },
            (error: WepError) => {
              this.notifier.errorAlert(this.msgs.releaseTaskError.msg);
            });
        } else {
          this.keycloakAuthService.userLogOut();
        }
      } else {
        this.keycloakAuthService.userLogOut();
      }
    });
  }

  /**
   * @description Subscribes to SCF Events
   * @return {void}
   */
  private subscribeToScfEvents(): void {
    // Notification Ceter
    this.scfComService.notificationReadSubscribe()
      .subscribe((notification: ScfNotification) => {
        // TODO: @rperez 2021/01/01 Put logic about notification center here.
      });

    // Sidebar & Search Box navigate to
    this.scfComService.navigateToSubscribe()
      .subscribe((routerLink: string) => {
        if (routerLink) {
          this.router.navigate([routerLink]);
        }
      });

    // User's panel solution selected
    this.scfComService.solutionChangedSubscribe()
      .subscribe((solution: string) => {
        if (solution) {
          this.solution = solution;
        }
      });

    // Logout Service
    this.scfComService.logoutSubscribe()
      .subscribe((navRequest: any) => {
        if (!navRequest) {
          this.keycloakAuthService.userLogOut();
        }
      });

    // Sidebar Service
    this.scfComService.sidebarSubscribe()
      .subscribe(() => {
        if (this.mobile) {
          this.display = !this.display;
          this.sidebarWrapperService.sidebarWrapperToggle(this.display);
        }
      });
  }

  /**
   * @description Breakpoint to validate if menu closes
   * @return {void}
   */
  public closePanelNotify(): void {
    this.display = false;
  }
}
