import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';

import { ENVIRONMENT } from '../../environments/environment';
import { Session } from './session';

const MODULE = 'session/';

@Injectable()
export class SessionService {

  constructor(private http: HttpClient) {}

  /**
   * @description This method returns the whole sessions from backend
   * @return {Observable<string>}
   */
  public getSessions(): Observable<string> {
    return this.http.get<string>(ENVIRONMENT.API + MODULE)
      .pipe(catchError((error: any) => throwError(error.json().error || 'Server error')));
  }

  /**
   * @description This method verifies the authenticated session of the backend validating its expiration
   * @return {Observable<string>}
   */
  public caducateSession(session: Session): Observable<string> {
    return this.http.delete<string>(ENVIRONMENT.API + MODULE + session.id)
      .pipe(catchError((error: any) => throwError(error.json().error || 'Server error')));
  }

  /**
   * @description This method verifies the authenticated backend session by verifying the muticuenta sessions that are successful
   * @return {Observable<string>}
   */
  public caducateAllSession(): Observable<string> {
    return this.http.delete<string>(ENVIRONMENT.API + MODULE)
      .pipe(catchError((error: any) => throwError(error.json().error || 'Server error')));
  }
}
