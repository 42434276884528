import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { LabelService } from '../../administration/label-management/label.service';

@Component({
  selector: 'app-custom-loading',
  templateUrl: './custom-loading.component.html',
  styleUrls: ['./custom-loading.component.css']
})
export class CustomLoadingComponent implements OnChanges {
  @Input() public showLoading: boolean;

  public lbl: any;

  constructor(private labelService: LabelService,
    private spinner: NgxSpinnerService) {
    this.lbl = {
      loadingTitle: this.labelService.labelText('loadingTitle')
    };
    this.showLoading = false;
  }

  /**
   *@description Evaluate the event when you click delete, to open the confirm.
   *@param {SimpleChanges} changes.
   */
  public ngOnChanges(changes: SimpleChanges) {
    let showValue = changes['showLoading'].currentValue;
    if (showValue) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }
}
