import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { AppSession } from './app-session';
import { ENVIRONMENT } from '../../environments/environment';
import { KcUser } from '../shared/authentication/models/kc-user';
import { KeycloakAuthenticationService } from '../shared/authentication/services/keycloak-authentication.service';
import { HandleError } from '../utils/utils';

@Injectable({ providedIn: 'root' })
export class AppSessionService {
  private user: KcUser;
  private appSessionSubject: BehaviorSubject<AppSession>;

  constructor(private http: HttpClient,
    private keycloakAuthService: KeycloakAuthenticationService) {
    this.user = this.keycloakAuthService.getTokenParsed();
    this.appSessionSubject = new BehaviorSubject<AppSession>(undefined);
  }

  /**
  * @description This method returns the current user's Session object stored in DB
  * @return {Session} current user's Session
  */
  public getAppSession(): Observable<AppSession> {
    return this.http.get<AppSession>(ENVIRONMENT.API + '/auth/app-session/user/' + this.user.userId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Emits app session via subject
   * @param {AppSession} appSession Data of the currrent app session.
   * @return {void}
   */
  public sendAppSession(appSession: AppSession): void {
    this.appSessionSubject.next(appSession);
  }

  /**
   * @description Retrieves the subject emmitted data as observable.
   * @return {Observable<AppSession>}
   */
  public getAppSessionAsObservable(): Observable<AppSession> {
    return this.appSessionSubject.asObservable();
  }
}
