import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { KeycloakAuthenticationService } from '../services/keycloak-authentication.service';
import { KeycloakTokenInterceptor } from '../interceptors/keycloak.interceptor';
import { KeycloakErrorInterceptor } from '../interceptors/error.interceptor';
import { LoginModule } from '../../../login/login.module';
import { PagesModule } from '../../../pages.module';

export function kcFactory(keycloakService: KeycloakAuthenticationService) {
  return async () => await keycloakService.init();
}

@NgModule({
  imports: [HttpClientModule, LoginModule, PagesModule],
  exports: [HttpClientModule, LoginModule, PagesModule],
  providers: [
    AuthService, KeycloakAuthenticationService,
    { provide: APP_INITIALIZER, useFactory: kcFactory, deps: [KeycloakAuthenticationService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: KeycloakTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: KeycloakErrorInterceptor, multi: true }
  ]
})
export class AuthModule { }
