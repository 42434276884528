import { AfterViewInit, Component, ChangeDetectorRef, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { ScfToastrService } from 'scf-library';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { ArrayUtils } from '../../utils/utils';
import { AuthService } from '../authentication/services/auth.service';
import { CONSTANTS } from '../constants';
import { HttpErrorResponse } from '@angular/common/http';
import { IMenuInterface } from '../../models/menu-interface';
import { LabelService } from '../../administration/label-management/label.service';
import { ReleaseTask } from '../interfaces/release-task';
import { ReleaseTaskService } from '../services/release-task.service';
import { SidebarWrapperService } from './sidebar-wrapper.service';
import { TaskGenericWrapperService } from '../templates/html/task-wrapper/task-wrapper.service';
import { TaskStatusEnum } from '../../process/task/task-enums';
import { KeycloakAuthenticationService } from '../authentication/services/keycloak-authentication.service';
import { KcUser } from '../authentication/models/kc-user';

@Component({
  selector: 'app-sidebar-wrapper',
  templateUrl: 'sidebar-wrapper.component.html',
  styleUrls: ['sidebar-wrapper.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarWrapperComponent implements OnInit, AfterViewInit {
  @Output() public closePanelMenu: EventEmitter<boolean>;
  public display: boolean;
  public menu: MenuItem[];
  public user: KcUser;
  public userSettingsItems: MenuItem[];
  public lbl = {
    backButton: this.labelService.labelText('backButton'),
    logoutBtn: this.labelService.labelText('sidebarLogoutButton'),
    successReleaseTask: this.labelService.labelText('taskWrapperUnSelectTask'),
    successTitle: this.labelService.labelText('infoTitle')
  };
  public msgs = {
    userInfoError: {
      msg: this.labelService.labelText('sidebarUserInfoError'),
      tlt: this.labelService.labelText('errorTitle')
    },
    releaseTaskError: {
      tlt: this.labelService.labelText('errorTitle'),
      msg: this.labelService.labelText('sidebarUserInfoError')
    }
  };

  constructor(private authService: AuthService,
    private keycloakAuthService: KeycloakAuthenticationService,
    private cdr: ChangeDetectorRef,
    private labelService: LabelService,
    private notifier: ScfToastrService,
    private releaseTaskService: ReleaseTaskService,
    private taskService: TaskGenericWrapperService,
    private sidebarWrapperService: SidebarWrapperService) {
    this.closePanelMenu = new EventEmitter<boolean>();
    this.menu = [];
    this.user = new KcUser();
  }

  public ngOnInit(): void {
    this.buildMenu();
    this.getLoggedUser();
    this.verifyDisplaySidebar();
  }

  /**
   * @description Verify if display or not sidebar.
   * @return {void}
   */
  public verifyDisplaySidebar(): void {
    this.display = _.isUndefined(this.display) ? this.display = false : this.display = true;
  }

  /**
   * @description After view has been initialized calls for defined methods and attributes
   * @return {void}
   */
  public ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.verifyWrapperStatusSidebar();
  }

  /**
   * @description Build the sidebar menu
   * @return {void}
   */
  public buildMenu(): void {
    this.menu = [];
    let menu: IMenuInterface[];
    menu = this.authService.getAllowedRoutes();
    menu = _.orderBy(menu, CONSTANTS.MOBILE_PRIORITY, CONSTANTS.ASCENDANT_ORDER);
    _.map(menu, (menuItem: IMenuInterface) => {
      if (menuItem.mobile) {
        let menuItemSize: number;
        let childItems: MenuItem[] = [];
        menuItem.items = _.remove(menuItem.items, { mobile: true });
        menuItemSize = _.size(menuItem.items);
        if (_.isEqual(menuItemSize, CONSTANTS.MIN_SIZE)) {
          menuItem.routerLink = menuItem.routerLink ? menuItem.routerLink : _.head(menuItem.items).routerLink;
          menuItem.items = [];
        }
        let item: MenuItem = {
          label: menuItem.title,
          icon: menuItem.icon,
          routerLink: menuItem.routerLink,
          command: (menuItemSize <= CONSTANTS.MIN_SIZE) ? () => { this.display = false; } : null
        };

        if (menuItem.items && !_.isEmpty(menuItem.items) && menuItem.mobile) {
          _.forEach(menuItem.items, (childMenu) => {
            let childItem: MenuItem = {
              label: childMenu.title,
              routerLink: childMenu.routerLinkMobile ? childMenu.routerLinkMobile : childMenu.routerLink,
              command: () => { this.display = false; }
            };
            if (childMenu.mobile) {
              childItems.push(childItem);
            }
          });
          if (_.isEqual(menuItem.name, CONSTANTS.REALLOCATE)) {
            item.items = childItems;
          } else {
            item.items = ArrayUtils.sortByCapitalLetter(childItems, 'title');
          }
        }
        this.menu.push(item);
      }
    });
  }

  /**
   * @description Gets user logged information
   * @return {void}
   */
  private getLoggedUser(): void {
    this.keycloakAuthService.getKcUserInfo().then((user: KcUser) => {
      this.user = user;
    },
      (error: HttpErrorResponse) => {
        if (!_.isEqual(error.status, CONSTANTS.AUTHORIZATION)) {
          this.notifier.errorAlert(this.msgs.userInfoError.msg);
        }
      }
    );
  }

  /**
   * @description Method to end session.
   * @return {void}
   */
  public logoutUser(): void {
    this.display = false;
    this.validateReleaseTask();
  }

  /**
   * @description validate release task.
   * @return {void}
   */
  private validateReleaseTask(): void {
    let realseTaskSubscription: Subscription;
    realseTaskSubscription = this.releaseTaskService.getReleaseTaskAsObservable().subscribe((taskToRelease: ReleaseTask) => {
      if (!_.isUndefined(taskToRelease)) {
        if (_.isEqual(taskToRelease.component, CONSTANTS.PICKING_LIST_COMPONENT) &&
          _.isEqual(taskToRelease.status, TaskStatusEnum.Assigned)) {
          // TODO: @rperez  2019/09/10 Refactor and use _find with file thath contains process screen.
          this.taskService.releasePickingList(taskToRelease).subscribe(() => {
            this.notifier.infoAlert(this.lbl.successReleaseTask);
            this.keycloakAuthService.userLogOut();
            this.releaseTaskService.releaseObservable();
          }, (error) => {
            this.notifier.errorAlert(this.msgs.releaseTaskError.msg);
          });
        } else {
          this.keycloakAuthService.userLogOut();
          realseTaskSubscription.unsubscribe();
        }
      } else {
        this.keycloakAuthService.userLogOut();
        realseTaskSubscription.unsubscribe();
      }
    });
  }

  /**
   * @description Breakpoint observer verify matches about observable.
   * @return {void}
   */
  public verifyWrapperStatusSidebar(): void {
    this.sidebarWrapperService.sidebarWrapperSubscribe().subscribe((displaySidebar: boolean) => {
      this.display = displaySidebar;
    });
  }

  /**
   * @description Validate if panel is hidden or not
   * @param {any} hidePanel flag to hide panel about
   */
  public onHidePanel(): void {
    this.closePanelMenu.emit(false);
  }
}
