export class KcUser {
  public userId?: string;
  public username?: string;
  public firstName?: string;
  public lastName?: string;
  public secondLastName?: string;
  public fullName?: string;
  public email?: string;
  public ​emailVerified?: boolean;
  public roles?: Array<string>;

  constructor() {
    this.userId = '';
    this.username = '';
    this.firstName = '';
    this.lastName = '';
    this.secondLastName = '';
    this.fullName = '';
    this.email = '';
    this.​emailVerified = null;
    this.roles = [];
  }
}
