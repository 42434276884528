import { WepConfig } from '../env-config/wep-config.interface';

export const ENVIRONMENT: WepConfig = {
  PRODUCTION: true,
  API: 'https://136-review-sso1-diellr.netlogistik.com',
  MSGTIME: '2000',
  PACKSTATIONAPI: 'https://917-review-sso1-diellr.netlogistik.com',
  DISPLAYTIME: '100',
  ALLOWEDROUTES: 'allowed_routes',
  OFFLINE_ERROR: 'OFFLINE_ERROR',
  CLIENTID: '5f67292d76431262648c5b5533db2ade',
  CLIENTSECRET: '5ebe2294ecd0e0f08eab7690d2a6ee69',
  RFCLIENTID: 'Y9aLkqQyTHo26t4ja9DZiuqoxvvM3v78',
  RFCLIENTSECRET: '7agv9ICHTlHPqNNRmecXWAf1AWFsonAz',
  RF: 'ws://192.168.10.183:3006',
  APIPLANNING: 'https://410-review-develop-3zknud.netlogistik.com/planning/api',
  API_INTEGRATOR: 'https://410-review-develop-3zknud.netlogistik.com/integrator/',
  googleMapsKey: 'AIzaSyB4YnyugZLz3nwGwJnojTj6q_B7BSej-tQ',
  APIPRINTER: 'http://api-printer',
  APPINSIGHTS_INSTRUMENTATIONKEY: '9a7b5938-08d7-4c97-818e-a6c0b27448c1',
  KEYCLOAK_SERVER_AUTH_URL: 'https://signin-develop.netlogistik.com/auth/',
  KEYCLOAK_REALM: 'wepProduction',
  KEYCLOAK_CLIENT_ID: 'wep-review',
};
