export class AppSession {
  id?: number;
  ip?: string;
  startDate?: Date;
  idKeycloak: string;
  warehouseId: number;
  equipmentId?: number;
  ipAddress?: string;
  userId: number;
  accountId?: number;
  applicationId?: number;
  user?: string;
  account?: string;
  warehouse?: string;
  equipment?: string;

  constructor() {
    this.id = undefined;
    this.ip = '';
    this.startDate = new Date();
    this.idKeycloak = '';
    this.warehouseId = undefined;
    this.equipmentId = undefined;
    this.ipAddress = '';
    this.userId = undefined;
    this.accountId = undefined;
    this.applicationId = undefined;
    this.user = '';
    this.account = '';
    this.warehouse = '';
    this.equipment = '';
  }
}
