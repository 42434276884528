import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { mergeMap } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { ScfToastrService } from 'scf-library';
import * as _ from 'lodash';

import { AuthService } from '../shared/authentication/services/auth.service';
import { CONSTANTS } from '../shared/constants';
import { EquipmentService } from '../catalog/equipment/equipment.service';
import { KcUser } from '../shared/authentication/models/kc-user';
import { KeycloakAuthenticationService } from '../shared/authentication/services/keycloak-authentication.service';
import { LabelService } from '../administration/label-management/label.service';
import { MobileConfig } from '../shared/constants/mobile-config';
import { PendingWork } from '../process/pending-work/pending-work';
import { PendingWorkService } from '../process/pending-work/pending-work.service';
import { TraslateUtils } from '../utils/traslate.utils';
import { User } from '../administration/user/user';
import { UserService } from '../administration/user/user.service';
import { version } from '../../../wep.properties.json';
import { Warehouse } from '../catalog/warehouse/warehouse';
import { WepError } from '../shared/wep-error';


@Component({
  selector: 'app-user-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {
  public lbl: any;
  public loginform: FormGroup;
  public mobile: boolean;
  public msgs: any;
  public selectedEquipmentId: number;
  public selectedWarehouse: Warehouse;
  public showEquipmentOption: boolean;
  public showWarehouseOption: boolean;
  public user: KcUser;
  public version: string;
  public warehouseMenu: any[];
  public warehouses: Warehouse[];
  public showLogin: boolean;

  constructor(private authService: AuthService,
    private equipmentService: EquipmentService,
    private keycloakAuthService: KeycloakAuthenticationService,
    private labelService: LabelService,
    private pendingWorkService: PendingWorkService,
    private router: Router,
    private notifier: ScfToastrService,
    private userService: UserService) {
      this.mobile = false;
      this.selectedWarehouse = null;
      this.showEquipmentOption = false;
      this.showWarehouseOption = false;
      this.version = version;
      this.warehouseMenu = [];
      this.warehouses = [];
      this.lbl = {
        errorTitle: this.labelService.labelText('errorTitle'),
        infoTitle: this.labelService.labelText('infoTitle'),
        loginButton: this.labelService.labelText('loginButton'),
        loginEquipment: this.labelService.labelText('loginEquipment'),
        loginEquipmentPlaceholder: this.labelService.labelText('loginEquipmentPlaceholder'),
        loginPassword: this.labelService.labelText('loginPassword'),
        loginPasswordPlaceholder: this.labelService.labelText('loginPasswordPlaceholder'),
        loginPoweredBy: this.labelService.labelText('loginPoweredBy'),
        loginUser: this.labelService.labelText('loginUser'),
        loginUserPlaceholder: this.labelService.labelText('loginUserPlaceholder'),
        loginUserRequired: this.labelService.labelText('loginUserRequired'),
        loginWarehouse: this.labelService.labelText('loginWarehouse'),
        subtitle: this.labelService.labelText('viewLoginSubtitle'),
        version: this.labelService.labelText('WEPVersion'),
        title: this.labelService.labelText('viewLoginTitle'),
      };
      this.msgs = {
        activeSessionError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('activeSessionError')
        },
        genericError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('genericErrorMessage')
        },
        loginEquipmentError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginEquipmentError')
        },
        loginEquipmentCantBeSelected: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginEquipmentCantBeSelected')
        },
        loginEquipmentNotAvailableError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginEquipmentNotAvailableError')
        },
        loginEquipmentWarehouseError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginEquipmentWarehouseError')
        },
        loginNoWarehouseError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginNoWarehouseError')
        },
        loginUserPasswordError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginUserPasswordError')
        },
        loginServerError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginServerError')
        },
        loginUserBlockedError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginUserBlockedError')
        },
        pendingWorkMessage: {
          tlt: this.lbl.infoTitle,
          msg: this.labelService.labelText('foundPendingWork')
        }
      };
  }

  public async ngOnInit() {
    this.user = await this.keycloakAuthService.getKcUserInfo();
    this.isActiveUserSession();
    this.validateTypeOfEquipment();
    this.validateLoggedInUser();
  }

  /**
   * @description Validates window's width to decide wheter or not it's a mobile request
   * @return {void}
   */
  public validateTypeOfEquipment(): void {
    this.mobile = window.screen.width >= MobileConfig.WINDOW_WIDTH ? false : true;
    this.selectedEquipmentId = this.mobile ? null : MobileConfig.DEFAULT_EQUIPMENT;
  }

  /**
    * @description This method gets all user sessions in keycloak server
    * and validates if it's a unique session, if not, logout the user.
    * @return {void}
    */
  public isUniqueUserSession(): void {
    this.userService.getUserSessions(this.user.userId).subscribe(
      (userSessions: any) => {
        if (_.size(userSessions) > 1) {
          this.notifier.errorAlert(this.msgs.activeSessionError.msg);
          this.keycloakAuthService.kcUserLogOut();
          throw new Error(this.msgs.activeSessionError.msg);
        } else {
          this.notifier.errorAlert(this.msgs.sessionExpired.msg);
          this.keycloakAuthService.userLogOut();
          throw new Error(this.msgs.sessionExpired.msg);
        }
      }, (error: WepError) => {
        this.notifier.errorAlert(this.labelService.getWepError(error.message));
        this.keycloakAuthService.kcUserLogOut();
      });
  }


  /**
  * @description This method gets all user sessions in keycloak server
  * and validates if there is one active, if not, logout the user.
  * @return {void}
  */
  public isActiveUserSession(): void {
    this.userService.getUserSessions(this.user.userId).subscribe(
      (userSessions: any) => {
        if (_.isEqual(_.size(userSessions), 0)) {
          this.notifier.errorAlert(this.msgs.sessionExpired.msg);
          this.keycloakAuthService.userLogOut();
          throw new Error(this.msgs.sessionExpired.msg);
        }
      }, (error: WepError) => {
        this.notifier.errorAlert(this.labelService.getWepError(error.message));
        this.keycloakAuthService.kcUserLogOut();
      });
  }

  /**
   * @description Validates if the user is authenticated and if so, redirects to home
   * @return {void}
   */
  public validateLoggedInUser(): void {
    let isAuthenticated: boolean = this.keycloakAuthService.isAuthenticated();
    if (isAuthenticated) {
      this.router.navigate([CONSTANTS.HOME_ROUTE]);
    } else {
      this.validateCurrentUser();
    }
  }

  /**
   * @description If login is correct, redirect the application to home
   * @return {void}
   */
  public loginRedirect(): void {
    this.selectedWarehouse = _.isEmpty(this.selectedWarehouse) ? _.head(this.warehouses) : this.selectedWarehouse;
    this.authService.validateWepUser(this.user, this.selectedWarehouse.id,
      this.selectedEquipmentId, this.mobile).subscribe(() => {},
        (error: WepError) => {
          this.notifier.errorAlert(this.labelService.getWepError(error.message));
          this.showWarehouseOption = false;
          this.keycloakAuthService.userLogOut();
        }, () => {
          if (this.mobile) {
            this.verifyPendingWork();
          }
          this.router.navigate([CONSTANTS.HOME_ROUTE]);
        });
  }

  /**
   * @description Validates if user exists in Wep database and gets all warehouses associated
   * @return {void}
   */
  public validateCurrentUser(): void {
    let warehouseId = 0;
    console.log(this.user, warehouseId, this.selectedEquipmentId, this.mobile);

    this.authService.validateWepUser(this.user, warehouseId, this.selectedEquipmentId, this.mobile)
      .pipe(mergeMap((validatedUser: User) => {
        return this.authService.getWarehousesInfoByUser(validatedUser);
      }))
      .subscribe((response: Warehouse[]) => {
        this.warehouses = response;
        this.selectedWarehouse = _.head(this.warehouses);
        _.map(this.warehouses, warehouse => {
          this.warehouseMenu.push({ label: warehouse.description, value: warehouse });
        });
        // Count how many warehouses there are to decide whether to show the menu or just login
        this.loginSelectWarehouse(_.size(this.warehouses));
      }, (error: WepError) => {
        if (error.message === CONSTANTS.ACTIVE_SESSION) {
          this.isUniqueUserSession();
        } else {
          this.notifier.errorAlert(this.labelService.getWepError(error.message));
          this.keycloakAuthService.userLogOut();
        }
      });
  }

  /**
   * @description Count how many warehouses there are to decide whether to show the menu or just login.
   * @return {void}
   */
  public loginSelectWarehouse(warehouseCount: number): void {
    switch (true) {
      // If there's more than one warehouse to select from, show the Warehouses Menu
      case (warehouseCount > 1):
        this.showLogin = true;
        this.showWarehouseOption = true;
        break;
      // If there's only one warehouse to choose from, select that one and login
      case (_.isEqual(warehouseCount, CONSTANTS.DEFAULT_WAREHOUSE)):
        this.loginSelectEquipment();
        break;
      // If there are no assigned warehouses, show the corresponding error
      default:
        this.notifier.errorAlert(this.msgs.loginNoWarehouseError.msg);
        break;
    }
  }

  /**
   * @description Redirects to equipment selection or select default equipment (desktop) if it's not a mobile request.
   * @return {void}
   */
  public loginSelectEquipment(): void {
    if (this.mobile) {
      this.showWarehouseOption = false;
      this.showEquipmentOption = true;
      this.showLogin = true;
    } else {
      this.loginRedirect();
    }
  }

  /**
   * @description Validates if select component is available. If it is, login user and update equipment's status.
   * @return {void}
   */
  public validateEquipment(): void {
    if (!_.isNull(this.selectedEquipmentId) && !_.isEqual(this.selectedEquipmentId, MobileConfig.DEFAULT_EQUIPMENT)) {
      this.equipmentService.verifyEquipmentWarehouse(this.selectedEquipmentId, this.selectedWarehouse.id)
        .subscribe(() => {
          this.loginRedirect();
        }, (error: WepError) => {
          this.notifier.errorAlert(this.labelService.getWepError(error.message));
        });
    } else {
      this.notifier.errorAlert(this.msgs.loginEquipmentCantBeSelected.msg);
    }
  }

  /**
   * @description Verifies if is there pending work
   * @return {void}
   */
  private verifyPendingWork(): void {
    this.pendingWorkService.findAnyPendingWork()
      .subscribe((pendingWork: PendingWork) => {
        let type = TraslateUtils.getTraslatedName(CONSTANTS.MOVEMENT_TYPE + pendingWork.type);
        this.notifier.infoAlert(this.msgs.pendingWorkMessage.msg.replace('%task_type%', type)
          .replace('%user%', pendingWork.user.nickname));
      },
      (error: WepError) => {
        if (!_.isEqual(error.message, CONSTANTS.MOVEMENT_HISTORY_CANNOT_BE_FOUND)) {
          this.notifier.infoAlert(this.labelService.getWepError(error.message));
        }
      });
  }
}
